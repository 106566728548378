import './App.css';
import { useMemo, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
// import Home from './view/Home';
import Header from './components/Header';
import Footer from './components/Footer';

import Home from './components/Home';
import { Toaster } from "react-hot-toast";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
// import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  // GlowWalletAdapter,
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  // BackpackWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import Container from "@mui/material/Container";

import { useCallback } from 'react';
import type { Adapter } from '@solana/wallet-adapter-base';
import type { SolanaSignInInput } from "@solana/wallet-standard-features";
import jwtDecode from "jwt-decode";
import { useCookies } from "react-cookie";
import DefaultTheme from "./utils/styles/DefaultTheme";
import trumpCet from "./assets/images/trump-cet.png";
import trumpCet2 from "./assets/images/trump-01.png";

// Default styles that can be overridden by your app
require("@solana/wallet-adapter-react-ui/styles.css");

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {

  const endpoint = "http://localhost:8899";

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter(),
      // new GlowWalletAdapter(),
      // new BackpackWalletAdapter(),
    ],
    []
  );

  const [cookies, setCookie, removeCookie] = useCookies();
  const [nfts, setNfts] = useState<(Object[]| null)>([]);

  const autoSignIn = useCallback(async (adapter: Adapter) => {
    // If the signIn feature is not available, return true
    if (!("signIn" in adapter)) return true;
  
    // Fetch the signInInput from the backend
    const createResponse = await fetch(`${process.env.REACT_APP_VOTING_API_URL}/api/auth/signin`);
  
    const input: SolanaSignInInput = await createResponse.json();

    // Send the signInInput to the wallet and trigger a sign-in request
    const output = await adapter.signIn(input);

    // Verify the sign-in output against the generated input server-side
    let strPayload = JSON.stringify({ input, output: {
      account: {
        address: output.account.address,
        publicKey: Array.from(output.account.publicKey),
      },
      signature: Array.from(output["signature"]),
      signedMessage: Array.from(output["signedMessage"]),
    } });

    const verifyResponse = await fetch(`${process.env.REACT_APP_VOTING_API_URL}/api/auth/verify`, {
      method: "POST",
      body: strPayload,
    });
    const responseJson = await verifyResponse.json();
    
    const success = responseJson.success;

    // If verification fails, throw an error
    if (!success) throw new Error("Sign In verification failed!");

    const decoded: any = jwtDecode(responseJson.token);

    if (decoded.pubkey !== process.env.REACT_APP_ADMIN_WALLET) {
      removeCookie('token');
      throw new Error("Wrong public key!");
    }

    setCookie('token', responseJson.token);

    return false;
  }, []);

  
  return (
    <div className="App">
      <ConnectionProvider endpoint={endpoint}>
          {/* for now we comment this out, since we have a token */}
          {/* <WalletProvider wallets={wallets} autoConnect={autoSignIn}> */}
          <WalletProvider wallets={wallets} autoConnect>
            <WalletModalProvider>
            <ThemeProvider theme={DefaultTheme}>
              <CssBaseline />
              <div>
             

              <Container maxWidth="lg">
                <div>
                  <Toaster  
                    position="top-right"
                    reverseOrder={true} />
                </div>

                {/* <Header /> */}

                <Routes>
                  <Route path="/" element={<Home nfts={nfts} setNfts={setNfts} />} />
                </Routes>
                <Footer />

              </Container>
       
      
              {/* <Box className="trump-container" sx={{ width: { xs: 170, md: 250 }, position: 'fixed', bottom: -10, right: { xs: -40, md: -45 } }}>
                <img src={trumpCet} width="100%" />
            </Box> */}

            {/* <Box className="trump-container" sx={{ width: { xs: 120, md: 200 }, position: 'fixed', bottom: -10, left: { xs: -25, md: -45 } }}>
                <img src={trumpCet2} width="100%" />
            </Box> */}
              </div>
            </ThemeProvider>
            </WalletModalProvider>
          </WalletProvider>
        </ConnectionProvider>
    </div>
  );
}

export default App;
