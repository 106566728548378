import * as anchor from "@coral-xyz/anchor";
import { AnchorWallet } from "@solana/wallet-adapter-react";
import { Connection, PublicKey, Keypair, SystemProgram } from "@solana/web3.js";
import idl from "./idl/cetsbets.json";
import moment from "moment";
import { Metaplex, walletAdapterIdentity, Metadata, Nft, Sft } from "@metaplex-foundation/js";
import { ProgramAccount } from "@coral-xyz/anchor";
import { TOKEN_METADATA_PROGRAM_ID, SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID, PAYMENT_ACCOUNT, TREASURY } from "./constants";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { sha256 } from "js-sha256";
import bs58 from 'bs58';

export const PROGRAM_ID = "8iM3eTyGtNaFFrgTwTegZ7Wo4YxwUonWQGdzwU16gFxG";
// export const ADMIN_PUBKEY = new anchor.web3.PublicKey('DAkpz8ywvDqk8WKfBACiF83Nbtcsw36hJJ9mUszaVBrq');
const ADMIN_PUBKEY = new anchor.web3.PublicKey('DgFCK84QNuSbuHZoZAZ8WphRB8C2UfEfRWkFQ8pDkh5t');
const HOUSE_WALLET = new anchor.web3.PublicKey('8Y3dUsFqBouVz71zRK9xXeKtDMnbYJfGQBnmhBpUWy1i');
const MICRO_WALLET = new anchor.web3.PublicKey('FdYm3jeWZN9gSMt42dLKQmNpbatkhjR1CEEJvxDQat4q');

const emptyKeypair = Keypair.generate();

export const emptyAnchorWallet: AnchorWallet = {
  // @ts-ignore
  signTransaction: () => {
    return undefined;
  },
  // @ts-ignore
  signAllTransactions: () => {
    return undefined;
  },
  publicKey: emptyKeypair.publicKey,
};

export const loadProgram = (
    connection: anchor.web3.Connection,
    wallet: AnchorWallet
  ): anchor.Program => {
    const provider = new anchor.AnchorProvider(connection, wallet, {
      preflightCommitment: "confirmed",
    });
  
    const votingProgramId = new PublicKey(PROGRAM_ID);
    const votingProgramInterface = JSON.parse(JSON.stringify(idl));
  
    const program = new anchor.Program(
      votingProgramInterface,
      votingProgramId,
      provider
    ) as anchor.Program;
  
    return program;
};

export const getNetworkConnection = (timeout = 30) => {
    const rpcUrl = process.env.REACT_APP_SOLANA_RPC_URL ?? 'https://api.devnet.solana.com';

    return new anchor.web3.Connection(rpcUrl, {
      confirmTransactionInitialTimeout: timeout * 1000, // timeout Seconds
      commitment: "finalized",
    });
};

export const getBetConfigPDA = (title: string, program: anchor.Program) => {
  return (
    anchor.web3.PublicKey.findProgramAddressSync(
    [Buffer.from("betconfig"), Buffer.from(title)],
    program.programId
  )
  );
}

export const getChoicePDA = (title: string, betConfig: anchor.web3.PublicKey, program: anchor.Program) => {
  return (
    anchor.web3.PublicKey.findProgramAddressSync(
    [Buffer.from("choice"), betConfig.toBuffer(), Buffer.from(title)],
    program.programId
  )
  );
}

export const getBetPDA = (betConfig: anchor.web3.PublicKey, owner: anchor.web3.PublicKey, choice: anchor.web3.PublicKey, program: anchor.Program) => {
  return (
    anchor.web3.PublicKey.findProgramAddressSync(
    [Buffer.from("bet"), owner.toBuffer(), choice.toBuffer()],
    program.programId
  )
  );
}

export const fetchChoices = async(anchorProgram: anchor.Program, betConfig: anchor.web3.PublicKey) => {
  const filters = [
    {
      memcmp: {
        offset: 16,
        bytes: betConfig.toBase58(),
      },
    },
  ];
  let choices = await anchorProgram.account.betChoice.all(filters);
  // console.log({ choices});
  return choices.sort((a: any, b: any) => {
    if (a.account.title < b.account.title) {
      return 1;
    } else if (b.account.title < a.account.title) {
      return -1;
    }

    return 0;
  });;
}


export const fetchBets = async(anchorProgram: anchor.Program, betConfig: anchor.web3.PublicKey, wallet: anchor.web3.PublicKey) => {
  const filters = [
    {
      memcmp: {
        offset: 80,
        bytes: wallet.toBase58(),
      },
    }
  ];
  let bets = await anchorProgram.account.bet.all(filters);

  console.log({ bets});
  return bets;
}

export const buildBetInstruction = async(anchorProgram: anchor.Program, betConfig: anchor.web3.PublicKey, wallet: AnchorWallet, choice: anchor.web3.PublicKey) => {
  const [betPDA, betBump] = await getBetPDA(betConfig, wallet.publicKey, choice, anchorProgram);
    
  const ix = await anchorProgram.methods.placeBet(
    ).accounts({
      betConfig: betConfig,
      choice: choice,
      bet: betPDA,
      houseWallet: HOUSE_WALLET,
      microWallet: MICRO_WALLET,
      owner: wallet.publicKey,
      systemProgram: anchor.web3.SystemProgram.programId,
      rent: anchor.web3.SYSVAR_RENT_PUBKEY,
  }).instruction()
// console.log({ix});
  return ix;
}

export const sendAndConfirmTransactionListCustom1 = async (
  wallet: AnchorWallet,
  connection: anchor.web3.Connection,
  transactionList: anchor.web3.Transaction[]
) => {
  const recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
  const transactionWithBlockhashList = transactionList.map((transaction) => {
    transaction.recentBlockhash = recentBlockhash;
    transaction.feePayer = wallet.publicKey;
    return transaction;
  });
  if (wallet.signAllTransactions) {
    const signedTransactionList = await wallet.signAllTransactions(
      transactionWithBlockhashList
    );
    const sendPromises = signedTransactionList.map(
      async (transaction) =>
        await sendAndConfirmTransactionWithRetries(connection, transaction)
    );
    await Promise.all(sendPromises);
  } else {
    for (const transaction of transactionWithBlockhashList) {
      const signedTransaction = await wallet.signTransaction(transaction);
      await sendAndConfirmTransactionWithRetries(connection, signedTransaction);
    }
  }
}

export const sendAndConfirmTransactionWithRetries = async (
  connection: Connection,
  transaction: anchor.web3.Transaction
) => {
  for (let i = 0; i <= 20; i++) {
    try {
      let txSign = undefined;
      try {
        txSign = await connection.sendRawTransaction(
          transaction.serialize(),
          {}
        );
      } catch (error) {
        console.log({ serializationError: error });
        throw error;
      }

      console.log(`Transaction sent(${i}): ${txSign}`);
      const result = await connection.confirmTransaction(txSign, "finalized");
      console.log({ result });
      break;
    } catch (error) {
      // @ts-ignore
      const errorMessage = error?.message;
      if (errorMessage) {
        if (
          errorMessage.includes("This transaction has already been processed")
        ) {
          break;
        }
        if (errorMessage.includes("Blockhash not found")) {
          throw error;
        }
      }
      // @ts-ignore
      console.log({ error, errorMessage: error?.message });
      if (i === 20) {
        throw error;
      }
    }
  }
};

export const getMetadataAccount = async (
  mint: anchor.web3.PublicKey
): Promise<anchor.web3.PublicKey> => {
  return (
    await anchor.web3.PublicKey.findProgramAddress(
      [
        Buffer.from("metadata"),
        TOKEN_METADATA_PROGRAM_ID.toBuffer(),
        mint.toBuffer(),
      ],
      TOKEN_METADATA_PROGRAM_ID
    )
  )[0];
};

export const getTokenAccount = async function (
  wallet: anchor.web3.PublicKey,
  mint: anchor.web3.PublicKey
) {
  return (
      await anchor.web3.PublicKey.findProgramAddress(
          [wallet.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), mint.toBuffer()],
          SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID
      )
  )[0];
};

export const formatTimestamp = (timestamp: any) => {
    return moment(timestamp * 1000).format('MM/DD/YYYY h:mma');
}

export const numVotes = (proposal: any) => {
  let numVotes = 0;
  
  for (let i = 0; i < proposal.choices.length; i++) {
      if (proposal.choices[i].tally) {
          numVotes += proposal.choices[i].tally.toNumber();
      }
  }

  return numVotes;
}


export const isActive = (proposal: any): boolean => {
  const start = proposal.account.startTime.toNumber();
  const end = proposal.account.endTime.toNumber();
  const now = Date.now() / 1000;

  return start <= now && end > now;
}

export const isUpcoming = (proposal: any): boolean => {
  const start = proposal.account.startTime.toNumber();
  const now = Date.now() / 1000;

  return start > now;
}

export const isCompleted = (proposal: any): boolean => {
  const start = proposal.account.startTime.toNumber();
  const end = proposal.account.endTime.toNumber();
  const now = Date.now() / 1000;

  return start < now && end < now;
}

export const isHidden = (proposal: any): boolean => {
  return proposal.account.isHidden;
}

export const linkify = (text: string) => {
  var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
  return text.replace(exp, "<a style='color: white;' href='$1' target='_blank'>$1</a>");
}