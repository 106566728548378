export const largeModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '90%', md: '75%' },
    height: { xs: '85%', md: '90%' },

    bgcolor: 'rgba(0, 0, 0, 0.8)',
    border: '2px solid rgba(0, 0, 0, 0.26)',
    boxShadow: 24,
    borderRadius: 2,
    // p: 3,
    outline: 'none',
    overflowY: 'scroll',
  };

  export const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '80%', md: '50%' },
    
    // bgcolor: 'rgba(255, 255, 255, 0.4)',
    bgcolor: '#0f0f0f',
    border: '2px solid rgba(0, 0, 0, 0.26)',
    // border: '2px solid rgba(0, 0, 0, 0.26)',
    boxShadow: 24,
    borderRadius: 2,
    p: 3,
    outline: 'none',
    overflowY: 'scroll',
  };

  export const betModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '80%', md: '40%' },
    // bgcolor: 'rgba(255, 255, 255, 0.4)',
    bgcolor: '#0f0f0f',
    // border: '2px solid rgba(0, 0, 0, 0.26)',
    boxShadow: 24,
    outline: 'none',
  };