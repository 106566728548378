import { WalletMultiButton } from  "@solana/wallet-adapter-react-ui";
import Box from "@mui/material/Box";
import { Button, Tooltip, Typography } from "@mui/material";
import cat from "../assets/images/cat-logo.png";
import catOnly from "../assets/images/cet.svg";
import { Link } from "react-router-dom";
import voidImg from "../assets/images/385-nobg.png";
import geronimoImg from "../assets/images/geronimo.png";

const Footer = () => {
    return (
      <Box my={3} display="flex" justifyContent="center">
          
          <Box display="flex" justifyContent="center" alignItems="center">
              <Typography variant="body2" pt={3}>dev</Typography>
              <Box>
            <Box display="flex" justifyContent="center">
                <Tooltip title="@voidmanevoid">
                <Button href="https://twitter.com/voidmanevoid" target="_blank">
                <img src={voidImg} width={100} className="void-img" />
                </Button>
                </Tooltip>

            </Box>
              </Box>
              
          </Box>

          <Box display="flex" justifyContent="center" alignItems="center">
              <Typography variant="body2" pt={3}>logo </Typography>
              <Box>
            <Box display="flex" justifyContent="center">
                <Tooltip title="@Gerominio87">
                <Button href="https://twitter.com/Gerominio87" target="_blank">
                <img src={geronimoImg} width={100} className="void-img" />
                </Button>
                </Tooltip>

            </Box>
              </Box>
              
          </Box>
      </Box>
    );
  };
  
  export default Footer;
  