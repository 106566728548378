import { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, Typography, Chip, CardHeader, CardActions, Grid, Modal, Tooltip } from "@mui/material"
import { loadProgram, getNetworkConnection, buildBetInstruction, sendAndConfirmTransactionListCustom1, emptyAnchorWallet, fetchChoices, fetchBets } from "../utils/utils";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { PublicKey, Transaction } from '@solana/web3.js'
import { toast } from "react-hot-toast";
import * as anchor from "@coral-xyz/anchor";
import { modalStyle, betModalStyle } from "../utils/styles";
import emptyFrame from "../assets/images/empty-frame.png";
import { WalletMultiButton } from  "@solana/wallet-adapter-react-ui";
import logo1 from "../assets/images/cets-logo2.png";

const SHDW_PREFIX = `https://img-cdn.magiceden.dev/rs:fit:640:0:0/plain/https://shdw-drive.genesysgo.net/3tPEmShThSrDVM364dUJPLjKCQMGScdPEP3XxgWgN2Xo`;

const snoozeCet = "DU4a2Ufk8KBQSdHBj5kyn1YvtQk1uYBRLomxFVJfhvxM";
const shawnCet = "77GR2QhBAADxjjeSngqMxziAuxvSD3N1uMPhbBHht9Ar";
const fxnctionCet = "https://prod-image-cdn.tensor.trade/images/slug=cets_on_creck/400x400/freeze=false/https%3A%2F%2Fshdw-drive.genesysgo.net%2FBSsQmdFzyJdeszLz7KCh5tVgTE3Ypv6DNdBaBoKZge6V%2F2-eda12742-80f1-11ed-b9ae-7a038cc9d891.png";
const honeyCet = "AGhgcnGW7pDVdL9eZHoSrX81f3BJBiAZUoZGTneDgur5";

// const betConfig = new PublicKey('DCjWncPRsgz4nZ6jAKE6v5DztcZDF5Lkf7jhznT25v5C');
const betConfig = new PublicKey('Du5vY9ThXnwRB4MMmFjVQSqWrYgnsig3xPyQefJDnCC2');

const BET_AMOUNT = 0.313;

const Home = (props: any) => {
    const [choices, setChoices] = useState<any[]>([]);
    const [bets, setBets] = useState<any[]>([]);
    const [isBetting, setIsBetting] = useState<boolean>(false);

    const [betChoice, setBetChoice] = useState<string>('');

    const anchorWallet = useAnchorWallet();
    const connection = getNetworkConnection(30);

    useEffect(() => {
        getChoices();
    }, []);

    useEffect(() => {
        if (anchorWallet && anchorWallet.publicKey) {
            getBets();
        }
    }, [anchorWallet]);

    const handleBetModalClose = () => {
        // getChoices();
        // getBets();
        
    }
    const getBets = async() => {
        if (anchorWallet && anchorWallet.publicKey) {
            let program = loadProgram(connection, anchorWallet);

            const bets = await fetchBets(program, betConfig, anchorWallet?.publicKey);
            setBets(bets);
        }
    }

    const getChoices = async() => {
        let wallet = (!anchorWallet || !anchorWallet.publicKey) ? emptyAnchorWallet : anchorWallet;
        let program = loadProgram(connection, wallet);

        const choices = await fetchChoices(program, betConfig);
        setChoices(choices);
        // console.log(choices);
    }

    const getNumBetsForChoice = (choice: anchor.web3.PublicKey) => {
        let count = 0;

        for (let i = 0; i < bets.length; i++) {
            if (bets[i].account.choice.toString() === choice.toString()) {
                count += bets[i].account.tally.toNumber();
            }
        }

        return count;
    }

    const getPotTotal = () => {
        let total = 0;

        for (let i = 0; i < choices.length; i++) {
            total += choices[i].account.tally.toNumber() * BET_AMOUNT;
        }

        return total.toFixed(4);
    }

    const handleBetClick = async(choice: anchor.web3.PublicKey) => {
        if (!anchorWallet || !anchorWallet.publicKey) {
            return;
        }
        const anchorProgram = loadProgram(connection, anchorWallet);
        
        for (let i = 0; i < choices.length; i++) {
            if (choices[i].publicKey.toString() === choice.toString()) {
                setBetChoice(choices[i].account.title.toString());
            }
        }

        setIsBetting(true);

        try {
        let txn = new Transaction();

        txn.feePayer = anchorWallet.publicKey;
        txn.recentBlockhash = (
            await connection.getLatestBlockhash("processed")
        ).blockhash;

        const betIx = await buildBetInstruction(anchorProgram, betConfig, anchorWallet, choice);
        txn.add(betIx);

        await sendAndConfirmTransactionListCustom1(anchorWallet, connection, [txn]);

        toast.success('Successfully bet!', { 
            position: "top-center"        
        });        
        }  catch (e) {
            console.log(e);
            toast.error('Unable to place bet!',
            {
              duration: 10000,
              style: {
                borderRadius: '6px',
                background: '#333',
                color: '#fff',
              },
              position: "top-center",
            }
          );
        } finally {
            setIsBetting(false);
            getChoices();
            getBets();
        }
    }

    return (
        <Box my={3}>
            
          

        <Card>
            
            <CardContent>
            <Box display="flex" justifyContent="space-between" mb={5}>
              <Box display={{ xs: 'none', md: 'flex' }} alignItems="center">
                  <img height="200" src={logo1} />
              </Box>

              <Box display={{ xs: 'flex', md: 'none' }}>
                <img height="125" src={logo1} />
              </Box>

                <Box alignSelf="center" textAlign="center" display={{ xs: 'none', md: 'block' }}>
                    <Typography variant="h5" >Who will be the Cets CEO? </Typography>
                    <Typography variant="body2" fontFamily="Space Grotesk" color="text.secondary" mt={{ xs: 2, md: 1 }}> Bet: 0.313 SOL | House Fee: 0.0069420 SOL</Typography>
                    <Typography variant="body2" fontFamily="Space Grotesk" color="rgba(255, 255, 255, 0.35)" mt={{ xs: 2, md: 1 }}  fontStyle="italic" sx={{ marginTop: 1 }}>Degens who pick the winner split the pot!</Typography>
                    <Typography variant="body2" fontFamily="Space Grotesk" mt={{ xs: 2, md: 1 }}  fontStyle="bold" sx={{ marginTop: 1 }}>BETS ARE CLOSED!</Typography>

                </Box>
  
                <Box >
                    <Box>
                        <WalletMultiButton className="wallet-button" />
                    </Box>
                    <Box textAlign="right" mr={2} mt={{ xs: 0, md: 2 }}>
                        <Chip variant="filled" label={`Pot: ` + getPotTotal() + ` SOL`} sx={{ marginTop: { xs: 3, md: 0 } }} color="info" />
                    </Box>
                </Box>
            </Box>

            <Box alignSelf="center" textAlign="center" display={{ xs: 'block', md: 'none' }} my={3}>
                <Typography variant="h5" fontSize="1.5rem">Who will be the Cets CEO? </Typography>
                <Typography variant="body2" fontSize=".85rem" fontFamily="Space Grotesk" color="text.secondary" mt={{ xs: 2, md: 1 }}> Bet: 0.313 SOL | House Fee: 0.0069420 SOL</Typography>
                <Typography variant="body2" fontSize=".85rem" fontFamily="Space Grotesk" color="rgba(255, 255, 255, 0.35)" mt={{ xs: 2, md: 1 }}  fontStyle="italic" sx={{ marginTop: 1 }}>Degens who pick the winner split the pot!</Typography>
            </Box>

<Grid container spacing={4}>
    {choices.length > 0 && choices.map(choice => <>
    <Grid item xs={12} md={6}>
        <Card sx={{ padding: { xs: 1, md: 2 } }}>
          

            {/* <CardContent> */}
                <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Box sx={{ position: 'relative' }}>
                        <Box sx={{ position: 'absolute', width: '100%', height: '100%' }}>
                            <img src={emptyFrame} width="100%" height="100%" />
                        </Box>

                        {choice.account.title.toString() === 'sn00ze' && <img width="100%" src={SHDW_PREFIX + `/` + snoozeCet + `.png`} />}
                        {choice.account.title.toString() === 'shawn' && <img width="100%" src={SHDW_PREFIX + `/` + shawnCet + `.png`} />}
                        {choice.account.title.toString() === 'honey b' && <img width="100%" src={SHDW_PREFIX + `/` + honeyCet + `.png`} />}
                        {choice.account.title.toString() === 'fxnction' && <img width="100%" src={fxnctionCet} />}
                    </Box>
                   
                </Grid>

                <Grid item xs={6}>
                <Box sx={{ position: 'relative', height: '100%'}}>

                    <Typography variant="h5">{choice.account.title.toString()} </Typography>
                    <Box display={{ xs: 'block', sm: "flex" }} mt={1}>
                            <Chip variant="filled" label={`My Bets: ` + getNumBetsForChoice(choice.publicKey)} color="info" sx={{ marginRight: 2, marginBottom: { xs: 1, md: 0 } }} /> 

                        <Chip variant="filled" label={`Total Bets: ` + choice.account.tally.toNumber()} color="info" /> 
                    </Box>
                    
                    
                    {/* <Box sx={{ marginTop: { xs: 2, md: 5 }, display: { xs: 'none', sm: 'flex' }, position: 'absolute', bottom: 6 }} alignSelf="flex-end" >
                    {(anchorWallet && anchorWallet.publicKey) &&
                    <Button variant="contained" color="success" sx={{ fontFamily: 'Space Grotesk', fontWeight: 700 }} onClick={() => handleBetClick(choice.publicKey)}>Bet 0.313 SOL</Button>}
            {(!anchorWallet || !anchorWallet.publicKey) &&
                    <Button variant="contained" color="success" sx={{ fontFamily: 'Space Grotesk', fontWeight: 700, textTransform: 'none' }} disabled={true}>Select Wallet</Button>}
                    </Box> */}
                    </Box>
                </Grid>

                <Grid item xs={12} display={{ xs: 'flex', sm: 'none' }} sx={{ marginLeft: 0 }}>
                {/* <Box>
                    {(anchorWallet && anchorWallet.publicKey) &&
                    <Button variant="contained" color="success" sx={{ fontFamily: 'Space Grotesk', fontWeight: 700 }} onClick={() => handleBetClick(choice.publicKey)}>Bet 0.313 SOL</Button>}
            {(!anchorWallet || !anchorWallet.publicKey) &&
                    <Button variant="contained" color="success" sx={{ fontFamily: 'Space Grotesk', fontWeight: 700, textTransform: 'none' }} disabled={true}>Select Wallet</Button>}

                    </Box> */}
                </Grid>
                </Grid>
               
            {/* </CardContent> */}
      
        </Card>
        
    </Grid></>)}
</Grid>

            </CardContent>
        
           
        </Card>

        <Modal
            open={isBetting}
            onClose={handleBetModalClose}
        >
            <Box sx={betModalStyle}>
                {isBetting && <Box position="relative">
                        <Box sx={{ position: 'absolute', width: '100%', height: '100%' }}>
                            <img src={emptyFrame} width="100%" height="100%" />
                        </Box>
                    <Box mb={2} pt={6} pb={3}>
                        <img src={require('../assets/images/trump-cet.png')} width="100%" />
                    </Box>
                    <Box textAlign="center" pt={3} pb={6}>
                        <Typography variant="h5">placing your bet for {betChoice}...</Typography>
                        <Typography variant="body1" color="text.secondary" sx={{ marginTop: 1 }}><em>is that mfer gonna win?!</em></Typography>
                    </Box>
                </Box>}
            </Box>
         </Modal>

        </Box>
    )
}

export default Home;
